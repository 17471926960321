import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { acceptRejectArticle } from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import active_info from "../../../svg/active-info.svg";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const RejectForPR = ({ showReject, setShowReject, articleId, modalTitle }) => {
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonInput, setReasonInput] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");

  const handleClose = () => setShowReject(false);
  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const onChangeOtherReason = (e) => {
    setReasonInput(e.target.value)
  }

  const onChangeReason = (e) => {
    setAdditionalComment(e.target.value);
  };

  const handleAcceptRejectArticle = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleId,
      peStatus: status,
      reason: reason === "Other" ? reasonInput : reason,
      peRejectedReasonAdditionalComment: additionalComment
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowReject(false);
      return navigate("/dashboard/publishing-editor");
    } else {
      return toast.error("Please try again!!");
    }
  };

  useEffect(() => {
    if (reason === "Other") {
      setDisabled(!reasonInput)
    } else {
      setDisabled(!reason)
    }
  }, [reason, reasonInput])

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      show={showReject}
      onHide={handleClose}
      className="md-x authorModal"
    >
      <Modal.Header className="modal-header">
        <h5 className="modal-title">
          {modalTitle === "REJECT_PUBLISH"
            ? "REJECT TO PUBLISH"
            : "REJECT FOR PEER REVIEW"}
        </h5>
        <button
          type="button"
          className="btn-close"
          ariaLabel="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="rejectModal">
          <div className="row">
            <div className="col-md-12">
              <p>Please specify your reason</p>
            </div>
          {
            modalTitle === "REJECT_PUBLISH" ? (
              <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Peer review report unfavorable"}
                  checked={reason === "Peer review report unfavorable"}
                />
                <b></b>
                <span>Peer review report unfavorable</span>
              </label>
            </div>
            ) : null
          }
            

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Inadequate discussion or conclusions"}
                  checked={reason === "Inadequate discussion or conclusions"}
                />
                
                <b></b>
                <span>Inadequate discussion or conclusions</span>
                <div className="customTool">
                  {["bottom"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <div className="popoverToolWrap">
                              <p>There are inadequacies in the discussion and conclusions presented in the manuscript under review. Despite the overall quality of the study, the author’s discussion fails to comprehensively address the implications of the findings, and the conclusions drawn are not firmly supported by the presented data. A more thorough exploration of the results, along with a critical analysis of their significance in relation to the existing literature, is necessary to strengthen the manuscript’s scientific contribution.</p>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <img
                        className="pe-cursor ps-1"
                        src={active_info}
                        alt="no img"
                      />
                    </OverlayTrigger>
                  ))}
                </div>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Article needs work on structure and language"}
                  checked={
                    reason === "Article needs work on structure and language"
                  }
                />
                <b></b>
                <span>Article needs work on structure and<br/>language<div className="customTool">
                  {["bottom"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <div className="popoverToolWrap">
                              <p>The overall organization of the article requires improvement, as it lacks a clear and coherent flow of ideas. Additionally, the language used throughout the manuscript needs refinement to enhance readability and comprehension for the intended audience. Addressing these aspects will significantly enhance the article’s quality and ensure its effectiveness in conveying the research findings.</p>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <img
                        className="pe-cursor ps-1"
                        src={active_info}
                        alt="no img"
                      />
                    </OverlayTrigger>
                  ))}
                </div></span>
                
              </label>
            </div>
              
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={
                    "Article doesn't make any new contribution to existing literature"
                  }
                  checked={
                    reason ===
                    "Article doesn't make any new contribution to existing literature"
                  }
                />
                <b></b>
                <span>
                  Article doesn't make any new contribution to existing
                  literature
                </span>
                <div className="customTool">
                  {["bottom"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <div className="popoverToolWrap">
                              <p>The content of the article appears to reiterate and summarize well-established knowledge without presenting any original insights, novel findings, or fresh perspectives that would advance the current state of research. The author may revise their work by incorporating original contributions that would enhance its value to the academic community.</p>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <img
                        className="pe-cursor ps-1"
                        src={active_info}
                        alt="no img"
                      />
                    </OverlayTrigger>
                  ))}
                </div>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Other"}
                  checked={reason === "Other"}
                />
                <b></b>
                <span>Any other reason</span>
              </label>
            </div>
            {reason === "Other" && (
              <div className="col-md-12">
                <textarea
                  className="inputField"
                  placeholder="Write here..."
                  onChange={(e) => onChangeOtherReason(e)}
                  value={reasonInput}
                  maxLength={100}
                ></textarea>
              </div>
            )}
            <div className="rejectPr">
              <span><b>Publishing Editor Comments</b></span>
              <textarea
                className="inputField"
                placeholder="Please add your comment here..."
                onChange={(e) => onChangeReason(e)}
                value={additionalComment}
                maxLength={100}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        <button
          class="button button-primary"
          type="submit"
          onClick={() => {
            // handleAcceptRejectArticle("rejectedApproval");
            handleAcceptRejectArticle("rejected");
          }}
          disabled={disabled}
        >
          SUBMIT
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectForPR;
